import {Component, OnInit, AfterViewInit, ViewChild, Input} from '@angular/core';
import { TitleCasePipe, registerLocaleData } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { FormsModule } from '@angular/forms';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr, 'fr');


import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, ColGroupDef, ValueFormatterParams, ValueGetterParams, INumberFilterParams, SizeColumnsToContentStrategy } from 'ag-grid-community';

import { AgChartsAngular } from 'ag-charts-angular';
import { AgCartesianChartOptions, AgScatterSeriesOptions, AgCartesianSeriesTooltipRendererParams } from 'ag-charts-community';


import { ApiService } from '../services/api.service';
import { CartService } from '../services/cart.service';
import { ComputeGridService } from '../services/compute-grid.service';

import { FormattedComputeData } from '../models/formatted-compute-data';

import { format_co2, format_consumption, format_currency, format_storage, format_number } from '../utils/formatter';

import { ButtonCellRendererComponent } from '../button-cell-renderer/button-cell-renderer.component';
import {ProviderFilter} from "../utils/provider-filter";


@Component({
    selector: 'app-compute',
    standalone: true,
    imports: [
        TitleCasePipe,
        FormsModule,
        AgGridAngular,
        AgChartsAngular,
        ButtonCellRendererComponent
    ],
    templateUrl: './compute.component.html',
    styleUrl: './compute.component.scss'
})
export class ComputeComponent implements OnInit, AfterViewInit {

    @Input()
    hideHeader = false;

    @Input()
    maxHeight = -1;

    constructor(
        public apiService: ApiService,
        private cartService: CartService,
        private filterComputeService: ComputeGridService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    @ViewChild('agGrid', { static: true }) agGridCompute!: AgGridAngular;

    titleCasePipe = new TitleCasePipe()
    formatted_compute_data!: FormattedComputeData[];
    chart_data!: FormattedComputeData[];

    chartOptions: AgCartesianChartOptions = {
        series: [],
        axes: [],
    };

    chart_shown: boolean = false;
    chart_axis_x: string = 'memory';
    chart_axis_y: string = 'od';
    chart_x_log: boolean = true;
    chart_y_log: boolean = true;

    only_with_green_data_available: boolean = true;
    strange_prices: boolean = false;
    show_debug: boolean = false;

    selectedProviders = {
      "Amazon EC2": true,
      "Google Cloud Platform": true,
      "Microsoft Azure": true,
    };

    ngOnInit(): void {
        this.apiService.compute_data.subscribe(data => {
            if (!data) return;

            data = data.filter(line => {
              return !this.only_with_green_data_available || line.emission_min !== null
            });

            this.formatted_compute_data = data;
            this.chart_data = data;
            this.create_chart_options(this.chart_data);
            this.update_chart();
            this.check_only_with_green_data_available();
        })
    }

    ngAfterViewInit(): void {
        this.agGridCompute.api.setGridOption('rowData', this.formatted_compute_data);
        const filters = this.filterComputeService.getFiltersFromQueryParams(this.route.snapshot.queryParams);
        if (filters) this.agGridCompute.api.setFilterModel(filters);
    }

    filterChanged(): void {
        const filterModel = this.agGridCompute.api.getFilterModel();
        this.filterComputeService.persistFilters(filterModel);
        const params = this.filterComputeService.getQueryParamsFromFilters();
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
            queryParamsHandling: 'merge',
        })

        this.chart_data = [];
        this.agGridCompute.api.forEachNodeAfterFilter(node => { this.chart_data.push(node.data) });
        this.create_chart_options(this.chart_data);
        this.update_chart();
    }

    clearAllFilters() {
        this.agGridCompute.api.setFilterModel(null);
    }

    onDeltaTimeChange() {
        this.agGridCompute.api.refreshCells()
        this.agGridCompute.api.onFilterChanged();
    }

    check_only_with_green_data_available(): void {
        this.agGridCompute.api.setGridOption('rowData', this.formatted_compute_data.filter(line => {
          return !this.only_with_green_data_available || line.emission_min !== null
        }));
    }

    check_strange_prices(): void {
        this.agGridCompute.api.setGridOption('rowData', this.formatted_compute_data.filter(line => {
            return !this.strange_prices || ((line.od && line['1yr'] && line['3yr']) && (line.od < line['1yr'] || line.od < line['3yr'] || line['1yr'] < line['3yr']))
        }));
    }

    create_chart_options(data: FormattedComputeData[]): void {
        const options: AgCartesianChartOptions = {
            title: { text: "Memory vs Od" },
            series: [],
            axes: [
                {
                    type: "log",
                    position: "bottom",
                    title: { text: "Memory" },
                },
                {
                    type: "log",
                    position: "left",
                    title: { text: "Od" },
                },
            ],
        };
        options.series = [];
        const data_splitted = this.separerParFournisseur(data);

        for (const fournisseur in data_splitted) {
            options.series.push({
                type: "scatter",
                data: data_splitted[fournisseur],
                xKey: "memory",
                yKey: "od",
                yName: `${fournisseur}`,
                tooltip: { renderer: this.renderer },
                marker: { size: 6 },
            })
        }
        this.chartOptions = options;
    }

    update_chart(): void {
        const options = { ...this.chartOptions };

        for (const series of options.series!) {
            (series as AgScatterSeriesOptions).xKey = this.chart_axis_x;
            (series as AgScatterSeriesOptions).yKey = this.chart_axis_y;
        }
        (options.title as any).text = `${this.titleCasePipe.transform(this.chart_axis_x)} vs ${this.titleCasePipe.transform(this.chart_axis_y)}`;
        (options.axes![0] as any).title.text = this.titleCasePipe.transform(this.chart_axis_x);
        (options.axes![1] as any).title.text = this.titleCasePipe.transform(this.chart_axis_y);
        (options.axes![0] as any).type = this.chart_x_log ? "log" : "number";
        (options.axes![1] as any).type = this.chart_y_log ? "log" : "number";

        this.chartOptions = options;
    }

    updateProvidersFilter = () => {
      console.log(`updateProvidersFilter => ${this.selectedProviders}`);
      let existingFilterModel = this.agGridCompute.api.getFilterModel();

      let selectedProvidersObjects = []
      for (let selectedProvider of Object.keys(this.selectedProviders)) {
        // @ts-ignore
        let providerIsSelected: boolean = this.selectedProviders[selectedProvider];
        if (providerIsSelected) {
          selectedProvidersObjects.push({
            filterType: "text",
            type: "equals",
            filter: selectedProvider
          });
        }
        console.log("plop");
      }
      existingFilterModel["provider"] = {
        filterType: "text",
        operator: "OR",
        conditions: selectedProvidersObjects
      }

      this.agGridCompute.api.setFilterModel(existingFilterModel);
      this.agGridCompute.api.refreshCells()
      this.agGridCompute.api.onFilterChanged();
    }

    separerParFournisseur(items: FormattedComputeData[]) {
        const fournisseursMap: { [fournisseur: string]: [FormattedComputeData] } = {};
        items.forEach(item => {
            const fournisseur: string = item.provider;
            if (!fournisseursMap[fournisseur]) {
                fournisseursMap[fournisseur] = [item];
            } else {
                fournisseursMap[fournisseur].push(item);
            }
        });
        return fournisseursMap;
    }

    renderer({ datum, xKey, yKey }: AgCartesianSeriesTooltipRendererParams) {
        return {
            title: `${datum.instanceType} - ${datum.provider}`,
            content: `Region : ${datum.region}<br>OS : ${datum.os}<br>${xKey} : ${datum[xKey]}<br>${yKey} : ${datum[yKey]}`,
        };
    }

    public autoSizeStrategy: SizeColumnsToContentStrategy = { type: "fitCellContents" };

    clickCount: number = 0;
    timeoutId: any;

    // to enable debug on triple click on something
    handleClick() {
        this.clickCount++;

        if (this.clickCount === 3) {
            this.show_debug = true;
            this.clickCount = 0;
            clearTimeout(this.timeoutId);
            return;
        }

        this.timeoutId = setTimeout(() => {
            this.clickCount = 0;
        }, 500);
    }

    computeColDefs: (ColDef | ColGroupDef)[] = [
      {
          field: "instanceType",
          headerName: "Instance Name",
          filter: "agTextColumnFilter",
          initialPinned: "left",
      },
      {
        headerName: 'Provider',
        children: [
          {
            field: "region",
            headerName: "Region",
            filter: "agTextColumnFilter",
          },
          {
            // columnGroupShow: 'open',
            field: "provider",
            headerName: "Provider",
            filter: "agTextColumnFilter",
            // filter: ProviderFilter,
          },
          {
            columnGroupShow: 'open',
            field: "country",
            headerName: "Country",
            filter: "agTextColumnFilter",
          },
          {
            columnGroupShow: 'open',
            field: "continent",
            headerName: "Continent",
            filter: "agTextColumnFilter",
          },
        ],
      },
        {
            headerName: 'Caracteristics',
            children: [
                {
                  field: "vcpu",
                  headerName: "vCPUs",
                  filter: "agNumberColumnFilter",
                  valueFormatter: (params: ValueFormatterParams): string => {
                    return format_number(params.value);
                  },
                  filterParams: {
                    defaultOption: 'greaterThanOrEqual',
                  } as INumberFilterParams,
                },
                {
                    field: "memory",
                    headerName: "Memory",
                    filter: "agNumberColumnFilter",
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return format_storage(params.value);
                    },
                    valueGetter: (params: ValueGetterParams): number => {
                        return params.data.memory / 1024;
                    },
                    filterParams: {
                        defaultOption: 'greaterThanOrEqual',
                    } as INumberFilterParams,
                },
                {
                    field: "os",
                    headerName: "OS",
                    filter: "agTextColumnFilter",
                },
            ],
        },
        {
            headerName: 'Pricing',
            children: [
                {
                    field: "od",
                    headerName: "On demand",
                    filter: "agNumberColumnFilter",
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return format_currency(params.value)
                    },
                    valueGetter: (params: ValueGetterParams): number => {
                        return params.data.od * this.apiService.deltatime;
                    },
                },
                {
                    field: "1yr",
                    headerName: "1 year",
                    filter: "agNumberColumnFilter",
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return format_currency(params.value)
                    },
                    valueGetter: (params: ValueGetterParams): number => {
                        return params.data['1yr'] * this.apiService.deltatime;
                    },
                },
                {
                    field: "3yr",
                    headerName: "3 years",
                    filter: "agNumberColumnFilter",
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return format_currency(params.value)
                    },
                    valueGetter: (params: ValueGetterParams): number => {
                        return params.data['3yr'] * this.apiService.deltatime;
                    },
                },
            ],
        },
        {
            headerName: 'Consumption',
            children: [
                {
                    field: "consumption_min",
                    headerName: "Min",
                    filter: "agNumberColumnFilter",
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return format_consumption(params.value);
                    },
                    valueGetter: (params: ValueGetterParams): number => {
                        return params.data.consumption_min * this.apiService.deltatime;
                    },
                },
                {
                    field: "consumption_max",
                    headerName: "Max",
                    filter: "agNumberColumnFilter",
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return format_consumption(params.value);
                    },
                    valueGetter: (params: ValueGetterParams): number => {
                        return params.data.consumption_max * this.apiService.deltatime;
                    },
                },
                {
                    columnGroupShow: 'open',
                    field: "cpu_consumption_min",
                    headerName: "CPU Min",
                    filter: "agNumberColumnFilter",
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return format_consumption(params.value);
                    },
                    valueGetter: (params: ValueGetterParams): number => {
                        return params.data.cpu_consumption_min * this.apiService.deltatime;
                    },
                },
                {
                    columnGroupShow: 'open',
                    field: "cpu_consumption_max",
                    headerName: "CPU Max",
                    filter: "agNumberColumnFilter",
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return format_consumption(params.value);
                    },
                    valueGetter: (params: ValueGetterParams): number => {
                        return params.data.cpu_consumption_max * this.apiService.deltatime;
                    },
                },
                {
                    columnGroupShow: 'open',
                    field: "memory_consumption",
                    headerName: "Memory",
                    filter: "agNumberColumnFilter",
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return format_consumption(params.value);
                    },
                    valueGetter: (params: ValueGetterParams): number => {
                        return params.data.memory_consumption * this.apiService.deltatime;
                    },
                },
                {
                    columnGroupShow: 'open',
                    field: "storage_consumption",
                    headerName: "Storage",
                    filter: "agNumberColumnFilter",
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return format_consumption(params.value);
                    },
                    valueGetter: (params: ValueGetterParams): number => {
                        return params.data.storage_consumption * this.apiService.deltatime;
                    },
                },
            ],
        },
        {
            headerName: 'Emission',
            children: [
                {
                    field: "emission_min",
                    headerName: "Min",
                    filter: "agNumberColumnFilter",
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return format_co2(params.value);
                    },
                    valueGetter: (params: ValueGetterParams): number => {
                        return params.data.emission_min * this.apiService.deltatime;
                    },
                },
                {
                    field: "emission_max",
                    headerName: "Max",
                    filter: "agNumberColumnFilter",
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return format_co2(params.value);
                    },
                    valueGetter: (params: ValueGetterParams): number => {
                        return params.data.emission_max * this.apiService.deltatime;
                    },
                },
                {
                    columnGroupShow: 'open',
                    field: "grey_emission",
                    headerName: "Grey Emission",
                    filter: "agNumberColumnFilter",
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return format_co2(params.value);
                    },
                    valueGetter: (params: ValueGetterParams): number => {
                        return params.data.grey_emission * this.apiService.deltatime;
                    },
                },
                {
                    columnGroupShow: 'open',
                    field: "cpu_co2_min",
                    headerName: "CPU CO2 Min",
                    filter: "agNumberColumnFilter",
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return format_co2(params.value);
                    },
                    valueGetter: (params: ValueGetterParams): number => {
                        return params.data.cpu_co2_min * this.apiService.deltatime;
                    },
                },
                {
                    columnGroupShow: 'open',
                    field: "cpu_co2_max",
                    headerName: "CPU CO2 Max",
                    filter: "agNumberColumnFilter",
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return format_co2(params.value);
                    },
                    valueGetter: (params: ValueGetterParams): number => {
                        return params.data.cpu_co2_max * this.apiService.deltatime;
                    },
                },
                {
                    columnGroupShow: 'open',
                    field: "memory_co2",
                    headerName: "Memory CO2",
                    filter: "agNumberColumnFilter",
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return format_co2(params.value);
                    },
                    valueGetter: (params: ValueGetterParams): number => {
                        return params.data.memory_co2 * this.apiService.deltatime;
                    },
                },
                {
                    columnGroupShow: 'open',
                    field: "storage_co2",
                    headerName: "Storage CO2",
                    filter: "agNumberColumnFilter",
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return format_co2(params.value);
                    },
                    valueGetter: (params: ValueGetterParams): number => {
                        return params.data.storage_co2 * this.apiService.deltatime;
                    },
                },
            ],
        },
        {
            field: "button",
            headerName: "Project simulator",
            initialPinned: "right",
            filter: false,
            cellRenderer: ButtonCellRendererComponent,
            cellRendererParams: {
                onClick: this.cartService.add_vm_to_cart.bind(this.cartService),
            }
        },
    ];

    defaultColDef: ColDef = {
        filter: true,
        floatingFilter: true,
    };
  protected readonly prompt = prompt;
}
